import React from 'react'
import './src/styles/global.css'
import { sendPageView } from './src/utils/events'
import { ApolloProvider } from '@apollo/client'
import { client } from './src/client'
import * as Sentry from '@sentry/gatsby'
import ErrorBoundary from './src/components/errorBoundary'

export const onRouteUpdate = ({ location }) => {
    if (typeof document !== 'undefined') {
        sendPageView({
            referrer: document.referrer,
            search: location.search
        })
        initKlaviyo()
    }

    if (typeof window !== 'undefined' && !window.fpr) {
        ;(function (w) {
            w.fpr =
                w.fpr ||
                function () {
                    w.fpr.q = w.fpr.q || []
                    w.fpr.q[arguments[0] === 'set' ? 'unshift' : 'push'](arguments)
                }
        })(window)

        window.fpr('init', { cid: 'b7b6x0gu' })
        window.fpr('click')

        const script = document.createElement('script')
        script.src = 'https://cdn.firstpromoter.com/fpr.js'
        script.async = true
        document.head.appendChild(script)
    }
}

export const wrapRootElement = ({ element }) => {
    return (
        <ErrorBoundary>
            <ApolloProvider client={client}>{element}</ApolloProvider>
        </ErrorBoundary>
    )
}

if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        // integrations: [Sentry.replayIntegration()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0
    })
}

const initKlaviyo = () => {
    !(function () {
        if (!window.klaviyo) {
            window._klOnsite = window._klOnsite || []
            try {
                window.klaviyo = new Proxy(
                    {},
                    {
                        get: function (n, i) {
                            return 'push' === i
                                ? function () {
                                      var n
                                      ;(n = window._klOnsite).push.apply(n, arguments)
                                  }
                                : function () {
                                      for (var n = arguments.length, o = new Array(n), w = 0; w < n; w++) o[w] = arguments[w]
                                      var t = 'function' == typeof o[o.length - 1] ? o.pop() : void 0,
                                          e = new Promise(function (n) {
                                              window._klOnsite.push(
                                                  [i].concat(o, [
                                                      function (i) {
                                                          t && t(i), n(i)
                                                      }
                                                  ])
                                              )
                                          })
                                      return e
                                  }
                        }
                    }
                )
            } catch (n) {
                ;(window.klaviyo = window.klaviyo || []),
                    (window.klaviyo.push = function () {
                        var n
                        ;(n = window._klOnsite).push.apply(n, arguments)
                    })
            }
        }
    })()
}
